import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/pixels.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/configure-amplify-client-side.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/components/error-alert.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@bugpilot+plugin-nextjs@0.19.1_next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@1_ugpnocyfdhwjjsv7aqbrt45oyu/node_modules/@bugpilot/plugin-nextjs/dist/client/Bugpilot.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@bugpilot+plugin-nextjs@0.19.1_next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@1_ugpnocyfdhwjjsv7aqbrt45oyu/node_modules/@bugpilot/plugin-nextjs/dist/client/WidgetTrigger.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.1.2/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.9_next@14.1.0_react@18.3.0-canary-b36ae8d7a-20231207_svelte@4.2.9_vue@3.4.15/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@vercel+toolbar@0.1.10_next@14.1.0_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/@vercel/toolbar/dist/next/localhost-controller.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@vercel+toolbar@0.1.10_next@14.1.0_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/@vercel/toolbar/dist/next/unmount.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/next/font/local/target.css?{\"path\":\"node_modules/.pnpm/geist@1.2.2_next@14.1.0/node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/next/font/local/target.css?{\"path\":\"node_modules/.pnpm/geist@1.2.2_next@14.1.0/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}")