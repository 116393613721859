"use client";

import { Amplify, ResourcesConfig } from "aws-amplify";
import { config } from "@/amplify-config";
import { Hub } from "aws-amplify/utils";
import { useRouter } from "next/navigation";

Amplify.configure(config as ResourcesConfig, { ssr: true });

export default function ConfigureAmplifyClientSide() {
  const router = useRouter();

  Hub.listen("auth", (data) => {
    const { payload } = data;
    switch (payload?.event) {
      // triggered for both oauth and email/pass auth
      case "signedIn":
        router.push("/workspace/select");
        break;
      // case "signInWithRedirect_failure":
      //   toast message if needed
      //   break;
    }
  });
  return null;
}
