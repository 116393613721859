"use client";
import { useSearchParams } from "next/navigation";
import { Toaster } from "./ui/toaster";
import { useToast } from "./ui/use-toast";
import { useEffect } from "react";

export function ToastMessage() {
  const searchParams = useSearchParams();
  const { toast } = useToast();

  useEffect(() => {
    const newError = searchParams.get("error");

    // write code for success message below

    if (!newError) {
      return;
    }

    toast({ title: "Uh-oh :(", description: newError, variant: "destructive" });
  }, [searchParams, toast]);

  return <Toaster />;
}
