/* eslint-disable @next/next/no-img-element, @next/next/no-html-link-for-pages, tailwindcss/no-custom-classname */
"use client";

import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";

// Twitter

export const TwitterPixel = () => {
  return (
    <Script
      strategy="beforeInteractive"
      id="twitter-track"
    >{`!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','ocfj9');`}</Script>
  );
};

export const TwitterPixel_TrackSignup = () => {
  // Signup
  // https://ads.twitter.com/conversion_events/18ce55j2tey/events_manager/ocfj9/event/t7li4/edit#step3
  return (
    <Script id="twitter-track-conversion">{`twq('event', 'tw-ocfj9-ojkbj', {});`}</Script>
  );
};

// Meta

export const MetaPixel = () => {
  const path = usePathname();
  const query = useSearchParams();

  useEffect(() => {
    window?.fbq?.("track", "PageView");
  }, [path, query]);

  return (
    <Script strategy="beforeInteractive" id="meta-track">
      {`!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
      n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '284727137948424');
    fbq('track', 'PageView');`}
    </Script>
  );
};

export const MetaPixel_TrackSignup = () => {
  return (
    <Script id="meta-track-conversion">{`fbq('track', 'Purchase', { value: 1.0, currency: "USD" });`}</Script>
  );
};

// Gtag

export const GtagAdwordsPixel = () => {
  // https://ads.google.com/aw/conversions?ocid=826089019&euid=583312949&__u=4020138701&uscid=826089019&__c=9178372131&authuser=0&subid=it-it-awhp-g-aw-c-home-signin%21o2-adshp-hv-q4-22
  // IMPORTANT: do not replace with the tag from TagManager (it will stop tracking ads conversions)
  return (
    <>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-8E4WG96SN5"
      ></Script>
      <Script strategy="beforeInteractive" id="gtm">
        {`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-8E4WG96SN5');
`}
      </Script>
    </>
  );
};

export const GtagPixel_TrackBeginSignup = () => {
  return (
    <Script id="gtag-track-conversion">{`gtag('event', 'conversion', {'send_to': 'AW-10821456327/UjyUCJGci48ZEMejiago'});`}</Script>
  );
};

// Fathom

export const FathomPixel = () => {
  return (
    <Script
      src="https://cdn.usefathom.com/script.js"
      data-site="ECBYFOJQ"
      defer
    ></Script>
  );
};

export const Fathom_TrackSignup = () => {
  return (
    <Script id="fathom-track-conversion">{`window.fathom?.trackEvent?.('signup_load');`}</Script>
  );
};

// Profitwell

export const Profitwell = () => {
  return (
    <Script
      id="profitwell-js"
      data-pw-auth="f59eca2f36d7a121de2ad327669e1f63"
    >{`(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
    a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
    s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
    })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');profitwell('start', {});`}</Script>
  );
};

export const Profitwell_Login = ({ email }: { email: string }) => {
  if (!email) {
    return null;
  }

  return (
    <Script id="profitwell-login">{`profitwell('user_email', '${email}')`}</Script>
  );
};

// 3rd party

export const PostHog = () => {
  return (
    <Script id="pineapple-ph">{`!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
    posthog.init('phc_VjOt8yKcd1kXUMbosKq8Q2OftMnwvjVe95yRaE9H3Ym',{api_host:'https://pineapple.bugpilot.com', disable_session_recording:true})`}</Script>
  );
};

export const PostHog_StartSessionRecording = () => {
  return (
    <Script id="posthog-start-session-recording">{`window.posthog?.startSessionRecording?.()`}</Script>
  );
};

export const Iubenda = () => {
  return (
    <>
      <Script id="iubenda-config">
        {`var _iub = _iub || [];
_iub.csConfiguration = {"cookiePolicyInOtherWindow":true,"countryDetection":true,"enableFadp":true,"enableLgpd":true,"enableRemoteConsent":true,"enableUspr":true,"lgpdAppliesGlobally":false,"perPurposeConsent":true,"siteId":2440905,"tcfPurposes":{"2":"consent_only","7":"consent_only","8":"consent_only","9":"consent_only","10":"consent_only"},"cookiePolicyId":29497684,"lang":"en","floatingPreferencesButtonDisplay":false,"banner":{ "acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0D0F19","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","brandBackgroundColor":"#FFFFFF","brandTextColor":"#0D0F19","closeButtonDisplay":true,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSizeBody":"10px","listPurposes":true,"logo":"http://www.bugpilot.com/icon.png","position":"float-bottom-left","rejectButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#DADADA","rejectButtonDisplay":true,"showPurposesToggles":true,"textColor":"#0D0F19" }};`}
      </Script>
      <Script
        id="iubenda-autoblocking"
        src="https://cs.iubenda.com/autoblocking/2440905.js"
      ></Script>
      <Script
        id="iubenda-gpp-stub"
        src="//cdn.iubenda.com/cs/gpp/stub.js"
      ></Script>

      <Script
        id="iubenda-cs"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        async
      ></Script>
    </>
  );
};

export const IubendaPreferencesWidget = () => {
  return (
    <>
      <li>
        <Link href="#" className="iubenda-cs-preferences-link">
          Privacy Choices
        </Link>
      </li>

      <li>
        <Link href="#" className="iubenda-cs-uspr-link">
          Notice at Collection
        </Link>
      </li>
    </>
  );
};
